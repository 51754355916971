var wipwrp = window.wipwrp || {};

wipwrp.catalogo = (function($) {

    var self = {};

    self.init = function () {
     
    }

    return self;
})(jQuery);